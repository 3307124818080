<template>
  <label
    class="relative"
    :for="id"
    :class="[
      ...containerClass,
      { 'pointer-events-none opacity-30 grayscale': props.disabled },
    ]"
  >
    <slot
      name="label"
      v-bind="{ label, id }"
    >
      <span :class="{ 'sr-only': !labelVisible }">{{ label }}</span></slot>
    <textarea
      :id="id"
      ref="textareaElement"
      v-bind="$attrs"
      v-model="inputValue"
      class="w-full rounded-lg border border-blue-100 p-5 text-sm outline-0 placeholder:text-blue-50 disabled:cursor-not-allowed"
      :class="[
        { '!border-errors ': error, 'resize-none': !props.resize },
        $attrs.class,
      ]"
      :aria-label="label"
      @change="onChange"
    />
  </label>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

interface UiTextare {
  id?: string
  label?: string
  modelValue: any
  resize?: boolean
  disabled?: boolean
  labelVisible?: boolean
  containerClass?: string[]
  error?: boolean
}

const props = withDefaults(defineProps<UiTextare>(), {
  iconName: '',
  id: '',
  label: '',
  variant: '',
  resize: true,
  disabled: false,
  labelVisible: false,
  containerClass: () => [],
  error: false,
})

const emit = defineEmits<{
  (e: 'update:model-value', value: any): void
  (e: 'change', value: Event): void
}>()

const textareaElement = ref<HTMLInputElement | null>(null)

const inputValue = computed({
  get: () => props.modelValue,
  set: value => emit('update:model-value', value),
})

function onChange(event: Event) {
  emit('change', event)
}

defineExpose({ textareaElement })
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
